
  /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
}


.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: #000;
  color: white;
  transform: rotateY(180deg);
}

.flip-card-forget {
  background-color: transparent;
  width: 30%;
  height: 280px;
  perspective: 1000px;
}
@media (max-width: 768px){
  .flip-card-forget {
    width: 90%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .flip-card-forget {
    width: 75%;
  }
}


.flip-card-inner-forget {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card-inner-forget.flipped {
  transform: rotateY(180deg);
}

.flip-card-front-forget, .flip-card-back-forget {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back-forget {
  color: white;
  transform: rotateY(180deg);
}

.roadmap {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.roadmap button {
  margin: 0 5px;
  padding: 5px 10px;
  opacity: 0.2;
  border: none;
  color: white;
  cursor: pointer;
}

.roadmap button.active {
  opacity: 100;
  color: white;
}
@keyframes slideInFromTop {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.slide-in-from-top1 {
  animation: slideInFromTop 0.5s ease-in-out;
}