.inner-shadow {
  box-shadow: inset #38bdf8 0px 0px 50px -10px; 
}
.inner-shadow2 {
  box-shadow: inset #0ea5e9 0px 0px 20px -10px; 
}
/* width */
::-webkit-scrollbar {
  display: none;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(30, 30, 30); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #000; 
}
.flip-card {
  background-color: transparent;
  width: 200px;
  height: 200px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: #000;
  color: white;
  transform: rotateY(180deg);
}

li::marker {
  color: #0ea5e9;
}
.flip-card2 {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px;
}
.flip-card-inner2 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card2:hover .flip-card-inner2 {
  transform: rotateY(180deg);
}

.flip-card-front2, .flip-card-back2 {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back2 {
  background-color: #000;
  color: white;
  transform: rotateY(180deg);
}

@media (max-width: 768px) {
  .flip-card2 {
    width: 250px;
    height: 250px;
    margin: auto;
  }
}