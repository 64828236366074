@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,400;12..96,800&family=Inria+Serif:ital,wght@0,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200;12..96,400;12..96,500;12..96,800&family=Inria+Serif:ital,wght@0,400;1,700&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inria+Serif:ital,wght@0,400;1,700&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&family=Inria+Serif:ital,wght@0,400;1,700&family=MuseoModerno:ital,wght@0,100..900;1,100..900&family=Nerko+One&family=Open+Sans:wght@400;500;800&family=Play&family=Poppins:wght@200&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Imprima&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Share+Tech&display=swap');