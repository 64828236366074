@keyframes slideInFromTop {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}

.slide-in-from-top {
    animation: slideInFromTop 2s ease-in-out;
}


@keyframes slide-in-from-left {
    from {
      transform: translateX(-40%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .slide-in-from-left {
    animation: slide-in-from-left 1s ease-in-out;
  }